<template>
  <div class="player">
    <div id="player"></div>
    <div id="title">{{name}}</div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      name: '',
    }
  },
  methods: {
    initPlayer(url) {
      flowplayer("#player", {
        src: url,
        autoplay: true,
        loop: false, // 循环播放
        // aspectRatio: "16:9", // 宽高比
        // poster: "https://dropbox.suntekcorps.com/data/attachment/dzz/202307/03/100252zaba7ewbggaie3hi.mp4", // 视频封面
        muted: false, // 是否静音
        // title: '换', // 视频标题，不生效
      })
    },
  },
  mounted() {
    // 获取地址参数
    const url = this.$route.query.url
    this.name = this.$route.query.name
    this.initPlayer(url)
  },
}
</script>
<style lang="scss" scoped>
  .player {
    width: 80%;
    height: 80vh;
    margin: 10vh auto;
    position: relative;
    #player {
      // width: 100%;
      height: 100%;
    }
    #title {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      line-height: 40px;
      text-align: center;
      color: #ffffff;
    }
  }
</style>